



























































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class PageFooter extends Vue {
  get isAcceptMargin() {
    if (!this.$route.matched.length) {
      return false
    } else {
      const path = this.$route.matched[0].path
      return (
        (path === '/summary' ||
          path === '/projects' ||
          path === '/projects/:unicodeName' ||
          path === '/projects/:unicodeName/:refLink') &&
        this.$vuetify.breakpoint.smOnly
      )
    }
  }
}
